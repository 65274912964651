const pushStateFiltersParams = (root, routeName, paramName, paramValue) => {
  const query = { ...root.$route.query }
  if (!paramValue || (!query.currentPage && paramName === 'currentPage' && paramValue === 1)) {
    delete query[paramName]
    root.$router.push({ name: routeName, query }).catch(() => {})
    return
  }
  query[paramName] = paramValue
  root.$router.push({ name: routeName, query }).catch(() => {})
}

export default pushStateFiltersParams
