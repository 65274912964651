import { ref, watch } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import store from '@/store'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useUsersList(root) {
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'name', sortable: true },
    { key: 'role_name', label: 'role', sortable: true },
    { key: 'last_login', label: 'last login', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]

  const defaultPerPage = Number(localStorage.getItem('usersPerPage'))
  const perPage = ref(defaultPerPage || DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const searchQuery = ref('')
  const filterDataStatus = ref('')
  const filterDataRoles = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  let debounceTimeout = ref(null)

  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const clearFilters = () => {
    filterDataRoles.value = ''
    filterDataStatus.value = ''
  }

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, filterDataStatus, filterDataRoles],
    ([currentPageVal, perPageVal, searchQueryVal, filterDataStatusVal, filterDataRolesVal],
      [currentPagePrevVal, perPagePrevVal, searchQueryPrevVal, filterDataStatusPrevVal, filterDataRolesPrevVal]) => {
      if (currentPageVal !== currentPagePrevVal) {
        pushStateFiltersParams(root, 'admin-users', 'currentPage', currentPageVal)
      }
      if (perPageVal !== perPagePrevVal) {
        pushStateFiltersParams(root, 'admin-users', 'perPage', perPageVal)
      }
      if (searchQueryVal !== searchQueryPrevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(root, 'admin-users', 'searchQuery', searchQueryVal)
        }, 250)
      }
      if (filterDataStatusVal !== filterDataStatusPrevVal) {
        pushStateFiltersParams(root, 'admin-users', 'filterDataStatus', filterDataStatusVal)
      }
      if (filterDataRolesVal !== filterDataRolesPrevVal) {
        pushStateFiltersParams(root, 'admin-users', 'filterDataRoles', filterDataRolesVal)
      }
    })

  const fetchUsersList = (ctx, callback) => {
    const query = { ...root.$route.query }
    const payloadData = {
      perPage: perPage.value,
      page: query.currentPage || 1,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      onlyAdminRoles: 1,
    }
    if (query.searchQuery) payloadData.name = query.searchQuery
    if (query.filterDataStatus) payloadData.status = query.filterDataStatus
    if (query.filterDataRoles) payloadData.role = query.filterDataRoles
    store
      .dispatch('app-users/fetchUsersList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta
        callback(data)
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const exportUsers = queryParams => axios
    .get('/auth/users/export', { params: queryParams })
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error exporting users',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const loginAsUser = queryParams => axios
    .post('/auth/login-as-user', { user_id: `${queryParams}` })
    .then(response => response.data.accessToken)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error get parent',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'Active') return 'success'
    if (status === 'Inactive') return 'danger'
    return 'primary'
  }

  const actionOptions = [
    { value: 'edit', title: 'Edit', link: 'admin-user-profile' },
  ]

  return {
    fetchUsersList,
    exportUsers,
    loginAsUser,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    filterDataStatus,
    filterDataRoles,
    sortBy,
    totalItems,
    isSortDirDesc,
    refInvoiceListTable,

    statusFilter,
    actionOptions,

    resolveStatusVariant,

    refetchData,
    clearFilters,
  }
}
