<template>
  <div class="text-center my-2">
    <b-spinner class="align-middle" />
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  components: { BSpinner },
}
</script>
