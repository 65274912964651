import useUsersList from '@/views/admin/users/users-list/useUsersList'

export const loginAs = {
  data() {
    return {
      schoolData: JSON.parse(localStorage.getItem('schoolData')),
    }
  },
  setup() {
    const { loginAsUser } = useUsersList()

    return { loginAsUser }
  },
  methods: {
    async loginAs(id, role) {
      const token = await this.loginAsUser(id)
      window.location.href = `${this.schoolData.front_url}login-as-user?user=${role}&userToken=${token}&url=${window.location.href}`
    },
  },
}

export const _ = null
